import React from 'react';
import QualityPicture from '../assets/images/clean.jpg';

export const  QualityComponent = () => {
	return (<section className="high-quality">
			<div className="container">
				<div className="high-quality_content">
					<div className="row clearfix">
						<div className="col-lg-5 col-md-5 col-sm-12">
							<div className="high-quality_image-box">
								<img src={QualityPicture}
								     alt=""/>
							</div>
						</div>
						<div
							className="offset-lg-1 col-lg-5 col-md-5 col-sm-12 offset-1">
							<div className="quality-sub-title">megfizethető és elérhető árak
							</div>
							<h2 className="sec_title">
								A higiéniás takarítás soha nem volt olyan fontos mint napjainkban.
							</h2>
							<div className="quality-sec-btn">
								<a className="quality_theme-btn"
								   href="#contact.html">
									Időpontfoglalás most!
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
