import React from 'react';


export const WorksComponent =  () => {
	return (<section className="service-works">
			<div className="shape-top-image">
				<img src="images/background/service-top-shape-img.png" alt=""/>
			</div>
			<div className="bg-service-works">
				<div className="container">
					<div className="sub-section-two">
						<div className="sub-logo">
							<img src="images/icons/cleaner-icon-img.png"
							     alt=""/>
						</div>
						<div className="sub-title_sec">
							<p>Igy dolgozunk</p>
						</div>
					</div>
					<div className="section-title">
						<h2>
							Hogyan <span>működik?</span>
						</h2>
						<p>
							<strong>Tudtad? </strong>Rendszeres megrendelés esetén, ugyanaz a takarító partner érkezik otthonodba.<br/>Amennyiben nem ugyanaz megy ki hozzád, arról te minden esetben tudni fogsz
						</p>
					</div>
					<div className="row clearfix">
						<div className="col-lg-4 col-md-6 col-sm-12">
							<div className="inner-box">
								<div className="arrow-one">
									<img src="images/icons/arrow-icon-img.png"
									     alt=""/>
								</div>
								<div className="icon-box_one">
									<div className="service-works__icon-box">
										<div
											className="service-works__icon"
											style={{
												backgroundImage: "url(images/resource/icon-bg.png)"
											}}
										>
											<img src="images/resource/icon.png"
											     alt=""/>
										</div>
										<div
											className="service-works__hover-icon"
											style={{
												backgroundImage: "url(images/resource/hover-icon-bg.png)"
											}}
										>
											<img
												src="images/resource/hover-icon.png"
												alt=""/>
										</div>
									</div>
									<span className="circle-text one">1</span>
									<h3 className="">Foglalj időpontot</h3>
									<p className=''>
										Telefonon, e-mailben vagy akár Viberen,
										Messengeren is!
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12">
							<div className="inner-box">
								<div className="icon-box_three">
									<div className="service-works__icon-box">
										<div
											className="service-works__icon"
											style={{
												backgroundImage: "url(images/resource/icon-bg.png)"
											}}
										>
											<img
												src="images/resource/message-icon-img.png"
												alt=""/>
										</div>
										<div
											className="service-works__hover-icon"
											style={{
												backgroundImage: "url(images/resource/hover-icon-bg.png)"
											}}
										>
											<img
												src="images/resource/message-hover-img.png"
												alt=""/>
										</div>
									</div>
									<span className="circle-text three">2</span>
									<h3 className="">Visszaigazoljuk a lehető leghamarabb</h3>
									<p className="">
										Beérkezést követően kollégáink igyekeznek mindig a lehető leggyorsabban visszaigazolni rendelését!
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12">
							<div className="inner-box">
								<div className="arrow-two">
									<img src="images/icons/arrow-icon-img.png"
									     alt=""/>
								</div>
								<div className="icon-box_two">
									<div className="service-works__icon-box">
										<div
											className="service-works__icon"
											style={{
												backgroundImage: "url(images/resource/icon-bg.png)"
											}}
										>
											<img
												src="images/resource/three-star-img.png"
												alt=""/>
										</div>
										<div
											className="service-works__hover-icon"
											style={{
												backgroundImage: "url(images/resource/hover-icon-bg.png)"
											}}
										>
											<img
												src="images/resource/icon-two-hover.png"
												alt=""/>
										</div>
									</div>
									<span className="circle-text two">3</span>
									<h3 className=""> 5 csillagos takarítás</h3>
									<p className="">
										Ha otthon szeret lenni közben, vagy távol a zajtol, akkor is biztos lehet benne, hogy 5 csillagos szolgáltatást kap
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="sec-link-btn">
						<div className="service-btn">
							<a className="theme-btn-three active"
							   href='#'>
								Időpontfoglalás most! <span
								className="flaticon-right-arrow"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
