import React from 'react';

export const FoooterComponent = () => {
	return (<footer className="main-footer" style={{paddingTop: 26}}>
			<div className="container">
				{/* Footer Bottom */}
				<div className="footer-bottom">
					<div className="clearfix">
						<div className="footer-bottom_center">
							<div className="copyright">
								Copyright By ©{" "}
								<a href="index.html">
									<span>Next-Media Label</span>
								</a>{" "}
								2021 - {new Date().getFullYear()}{" "}
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
