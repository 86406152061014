export const fadeOutElement = (el, speed, hide = true) => {
  const seconds = speed/1000;
  el.style.transition = "opacity "+seconds+"s ease";

  el.style.opacity = 0;
  setTimeout(function () {
    el.style.visibility = 'hidden';
  }, speed);
  /*
  if(hide) {
      setTimeout(function () {
        el.parentNode.removeChild(el);
      }, speed);
  } else {
    setTimeout(function () {
      el.style.visibility = 'hidden';
    }, speed);
  }

   */
}
export const fadeInElement = (el, speed, hide = true) => {
  const seconds = speed/1000;
  el.style.opacity = 0;
  el.style.visibility = 'visible';
  el.style.transition = "opacity "+seconds+"s ease";
  el.style.opacity = 1;
}
