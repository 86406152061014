import React from 'react';

export const HeaderComponent = () => {
	return (<header className="main-header">
			{/* Header Upper */}
			<div className="header-upper clearfix">
				<div className="auto-container">
					<div className="logo-outer">
						<div className="logo">
							<a href="/">
								<img height={70} src={require('../assets/images/tiszta-logo.jpg')} alt="Tiszta otthon, tiszta lélek"
								     title=""/>
							</a>
						</div>
					</div>
					{/* Main Menu */}
					<nav className="main-menu navbar-expand-md navbar-light">
						<div className="navbar-header">
							{/* Toggle Button */}
							<button
								className="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="icon flaticon-menu-button"/>
							</button>
						</div>
						
						<div className={'nav-outer clearfix'}>
							<div
								className="collapse navbar-collapse clearfix"
								id="navbarSupportedContent"
							>
								<ul className="navigation clearfix">
									
									<li>
										<a href="#rolunk">Rólunk</a>
									</li>
									<li>
										<a href="#szolgaltatasok">Szolgáltatások</a>
									</li>
									<li>
										<a href="#szolgaltatasok">Referenciáink</a>
									</li>
									<li>
										<a href="#kapcsolat">Kapcsolatfelvétel</a>
									</li>
								</ul>
								<div className="btn-box" style={{}}>
									<a href="tel:123123"
									   className="theme-btn btn-style-one">Hívjon
										bizalommal!</a>
								</div>
						</div>
				</div>
			</nav>
		{/* Main Menu End*/}
				</div>
			</div>
			{/*End Header Upper*/}
			{/* Sticky Header  */}
			<div className="sticky-header">
				<div className="container clearfix">
					{/*Logo*/}
					<div className="logo pull-left">
						<a href="index.html" title="">
							<img src="images/header-logo.png" alt="" title=""/>
						</a>
					</div>
					{/*Right Col*/}
					<div className="pull-right">
						{/* Main Menu */}
						<nav className="main-menu">
							<div
								className="navbar-collapse show collapse clearfix">
								<ul className="navigation clearfix">
									<li>
										<a href="#rolunk">Rólunk</a>
									</li>
									<li>
										<a href="#szolgaltatasok">Szolgáltatások</a>
									</li>
									<li>
										<a href="#szolgaltatasok">Referenciáink</a>
									</li>
									<li>
										<a href="#kapcsolat">Kapcsolatfelvétel</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</header>
	)
}
