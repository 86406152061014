import React from 'react';

export const BannerComponent = () => {
	return (<section className="main-slider">
			<div className="parallax-scene parallax-scene-2 parallax-icon">
				<span data-depth="0.40" className="parallax-layer icon icon-1"/>
				<span data-depth="0.50" className="parallax-layer icon icon-2"/>
			</div>
			<div className="container">
				<div className="row clearfix">
					<div className="col-lg-7 col-md-12 col-sm-12">
						<div className="banner-style">
							<h1 className="banner-title">
								Professzionális takarítás <span>otthonoknak</span> és <span>cégeknek, irodáknak</span>
							</h1>
							<p>
								Válassza professzionális takarítási szolgáltatásunkat a tökéletes tisztaságért!
							</p>
						</div>
						<div className="banner-btn">
							<a className="link-btn" href="#contact.html">
								Lépjen kapcsolatba velünkk
							</a>
						</div>
					</div>
					<div className="col-lg-5 col-md-12 col-sm-12">
						<div className="banner-image">
							<img src="images/main-slider/banner-bg-image.png"
							     alt=""/>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-shape-image">
				<img src="images/background/bg-slider-shape1.png" alt=""/>
			</div>
		</section>
	)
}
