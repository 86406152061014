import React from 'react';

export const ServicesComponent = () => {
	return (<section className="solution-work">
			<div className="container clearfix">
				<div className="sub-section-two">
					<div className="sub-logo">
						<img src="images/icons/cleaner-icon-img.png" alt=""/>
					</div>
					<div className="sub-title_sec">
						<p>Mivel is foglalkozunk</p>
					</div>
				</div>
				<div className="section-title">
					<h2>
						Hogyan dolgozunk <span>Önöknél?</span>
					</h2>
					<p>
						Teljes felújítás, építkezés, festés, tűzkár, vízkár
						utáni teljes, alapos és szakszerű nagytakarítás
						magánszemélyeknek is.
						<br/>
						<strong>A káresemény utáni rend és tisztaság helyreállítása</strong><br/>
						<strong>Belső terek precíz takarítása a lakásátalakítás,
							építkezés befejeztével</strong><br/>
						<strong>Nagy méretű bútorok és szőnyegek helyszíni
							tisztítása</strong><br/>
					</p>
				</div>
				<div className="row clearfix">
					<div className="col-lg-3 col-md-6 col-sm-12">
						<div
							className="content-block wow slideInUp animated animated"
							data-wow-delay="600ms"
							data-wow-duration="1500ms"
							style={{
								visibility: "visible",
								animationDuration: "1500ms",
								animationDelay: "600ms",
								animationName: "slideInUp"
							}}
						>
							<div className="single-item work">
								<div className="icon-img-box">
									<img
										src="images/resource/Commercial-Cleaning.png"
										alt=""/>
								</div>
								<h2 className="number-text">01</h2>
								<h4 className="single-item-title">Otthonok
									általános és nagytakarítása</h4>
								<p className="choose_sub-title">
									Házak, lakások rendszeres és alkalmi
									általános és nagytakarítása szakképzett
									személyzettel, rövid határidővel.
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12">
						<div
							className="content-block wow slideInUp animated animated"
							data-wow-delay="600ms"
							data-wow-duration="1500ms"
							style={{
								visibility: "visible",
								animationDuration: "1500ms",
								animationDelay: "600ms",
								animationName: "slideInUp"
							}}
						>
							<div className="single-item work">
								<div className="icon-img-box">
									<img
										src="images/resource/Residental-Cleaning.png"
										alt=""/>
								</div>
								<h2 className="number-text">02</h2>
								<h4 className="single-item-title">Irodák, irodaházak takarítása</h4>
								<p className="choose_sub-title">
									Munkahelyek, irodák, üzletek és gyárak szakszerű takarítása kedvező áron akár éjszaka/hétvégén is!
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 single-column">
						<div
							className="content-block wow slideInUp animated animated"
							data-wow-delay="600ms"
							data-wow-duration="1500ms"
							style={{
								visibility: "visible",
								animationDuration: "1500ms",
								animationDelay: "600ms",
								animationName: "slideInUp"
							}}
						>
							<div className="single-item work">
								<div className="icon-img-box">
									<img
										src="images/resource/window-cleaner.png"
										alt=""/>
								</div>
								<h2 className="number-text">03</h2>
								<h4 className="single-item-title">Társasházak, lépcsőházak takarítása</h4>
								<p className="choose_sub-title">
									Társasházak rendszeres takarítása, felmosással, fertőtlenítéssel, akár alapvető gondnoki feladatok ellátásával
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 single-column">
						<div
							className="content-block wow slideInUp animated animated"
							data-wow-delay="600ms"
							data-wow-duration="1500ms"
							style={{
								visibility: "visible",
								animationDuration: "1500ms",
								animationDelay: "600ms",
								animationName: "slideInUp"
							}}
						>
							<div className="single-item work">
								<div className="icon-img-box">
									<img
										src="images/resource/Kitchen-Cleaning.png"
										alt=""/>
								</div>
								<h2 className="number-text">04</h2>
								<h4 className="single-item-title">Konyha és fürdőszoba</h4>
								<p className="choose_sub-title">
									Súrolás, konyhai zsíroldás
									WC fertőtlenítés-, és vízkőoldás
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="solution-bottom-content"
				     style={{marginTop: 30}}>
					<div className="row clearfix">
						<div className="col-lg-3 col-md-6 col-sm-12">
							<div
								className="content-block wow slideInUp animated animated"
								data-wow-delay="600ms"
								data-wow-duration="1500ms"
								style={{
									visibility: "visible",
									animationDuration: "1500ms",
									animationDelay: "600ms",
									animationName: "slideInUp"
								}}
							>
								<div className="single-item work">
									<div className="icon-img-box">
										<img
											src="images/resource/Commercial-Cleaning.png"
											alt=""/>
									</div>
									<h2 className="number-text">05</h2>
									<h4 className="single-item-title">Szőnyeg- és kárpittisztítás</h4>
									<p className="choose_sub-title">
										Ha már unod, hogy nem tudod milyen a szőnyeged eredeti színe és szeretnél újra mezítláb mászkálni rajta.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-sm-12">
							<div
								className="content-block wow slideInUp animated animated"
								data-wow-delay="600ms"
								data-wow-duration="1500ms"
								style={{
									visibility: "visible",
									animationDuration: "1500ms",
									animationDelay: "600ms",
									animationName: "slideInUp"
								}}
							>
								<div className="single-item work">
									<div className="icon-img-box">
										<img
											src="images/resource/window-cleaner.png"
											alt=""/>
									</div>
									<h2 className="number-text">06</h2>
									<h4 className="single-item-title">Ablak és üvegtisztítás</h4>
									<p className="choose_sub-title">
										Ha már hiányzik, hogy kiláss az ablakodon és újra leskelődnél a szomszédod után.
									</p>
								</div>
							</div>
						</div>
						<div
							className="col-lg-3 col-md-6 col-sm-12 single-column">
							<div
								className="content-block wow slideInUp animated animated"
								data-wow-delay="600ms"
								data-wow-duration="1500ms"
								style={{
									visibility: "visible",
									animationDuration: "1500ms",
									animationDelay: "600ms",
									animationName: "slideInUp"
								}}
							>
								<div className="single-item work">
									<div className="icon-img-box">
										<img
											src="images/resource/window-cleaner.png"
											alt=""/>
									</div>
									<h2 className="number-text">07</h2>
									<h4 className="single-item-title">
										Lakás-, iroda- és jármű fertőtlenítés</h4>
									<p className="choose_sub-title">
										Ha fontos számodra az egészséged és nem szeretnél baktériumokkal osztozkodni az otthonodon.
									</p>
								</div>
							</div>
						</div>
						<div
							className="col-lg-3 col-md-6 col-sm-12 single-column">
							<div
								className="content-block wow slideInUp animated animated"
								data-wow-delay="600ms"
								data-wow-duration="1500ms"
								style={{
									visibility: "visible",
									animationDuration: "1500ms",
									animationDelay: "600ms",
									animationName: "slideInUp"
								}}
							>
								<div className="single-item work">
									<div className="icon-img-box">
										<img
											src="images/resource/Kitchen-Cleaning.png"
											alt=""/>
									</div>
									<h2 className="number-text">8</h2>
									<h4 className="single-item-title">Költöztetés, lomtalanítás</h4>
									<p className="choose_sub-title">
										Ha már nem tudod hova pakolni a régi bútorokat, kinőtt ruhákat és nincs kedved még hónapokig tárolni őket.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Button Box */}
				<div className="service-btn-box">
					<a href="contact.html" className="theme-btn btn-style-one">
						Quick Contact
					</a>
				</div>
			</div>
		</section>
	)
}
