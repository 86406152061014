import React from 'react';
import './App.css';
import {HeaderComponent} from './modules/Header';
import {BannerComponent} from './modules/Banner';
import {ServicesComponent} from './modules/Services';
import {FoooterComponent} from './modules/Footer';
import {WorksComponent} from './modules/Works';
import {QualityComponent} from './modules/Qualty';
import {ContactComponent} from './modules/Contact';
import {fadeOutElement} from './utils/dom'

function App() {
    React.useEffect(() => {
        if (document.getElementById('page-loader')) {
            setTimeout(() => fadeOutElement(document.getElementById('page-loader'), 1100, false), 2000)
        }
    }, [])
  return (
    <div className="page-wrapper">
      <HeaderComponent/>
      <BannerComponent/>
        <ServicesComponent/>
        <WorksComponent/>
        <QualityComponent/>
        <ContactComponent/>
        <FoooterComponent/>
    </div>
  );
}

export default App;
