import React from 'react';

export const ContactComponent = () => {
	return (<section className="our-loaction" style={{marginTop: 100, marginBottom: 80}}>
			<div className="container">
				<div className="sub-section-two">
					<div className="sub-logo">
						<img src="images/icons/cleaner-icon-img.png" alt=""/>
					</div>
					<div className="sub-title_sec">
						<p>Elérhetöségeink</p>
					</div>
				</div>
				<div className="section-title">
					<h2>
						Hogyan is találsz meg
						<span> minket?</span>
					</h2>
				</div>
				<div className="row clearfix">
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="location-content">
							<div className="location-map-icon">
								<img src="images/resource/addres-icon-img.png"
								     alt=""/>
							</div>
							<div className="office-address">
								<h4>Levelezêsi címünk</h4>
								<p>1012 Budapest, Minta utca 7.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="location-content">
							<div className="telephone-icon">
								<img src="images/resource/akar-icons_phone.png"
								     alt=""/>
							</div>
							<div className="contact-text">
								<h4>Telefonszámunk</h4>
								<p>
									<a href="tel:+36101234567">+36-10/123-4567</a>
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12">
						<div className="location-content">
							<div className="mail-address-icon">
								<img src="images/resource/charm_mail-icon.png"
								     alt=""/>
							</div>
							<div className="mail-address-content">
								<h4>E-mail címünk</h4>
								<p>
									<a href="minfo@oldalad.hu">info@oldalad.hu</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
